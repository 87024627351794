import { createApp } from "vue";
import App from "./App.vue";
import { createHead } from "@vueuse/head";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap";
import "../src/assets/css/style.css";
import "aos/dist/aos.css";
import router from "./router";

const app = createApp(App);

// let baseUrl = "http://localhost:9100/";
let baseUrl = "https://cityhospital.autofyapps.com/";

let siteUrl ="https://cityhospitalbd.com/"

app.config.globalProperties.$apiBaseUrl = baseUrl;
app.config.globalProperties.$siteUrl = siteUrl;
app.config.globalProperties.$apiAccessKey = '123456789';


const head = createHead(); 
app.use(router).use(head).mount("#app");


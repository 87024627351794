import ContactUs from "@/components/Home/ContactUs.vue";
import HomePage from "@/components/Home/HomePage.vue";
import AboutUs from "@/views/AboutUs.vue";
import DepartmentDetails from "@/views/DepartmentDetails.vue";
import DepartmentDoctor from "@/views/DepartmentDoctor.vue";
import DoctorDetails from "@/views/DoctorDetails.vue";
import DoctorsList from "@/views/DoctorsList.vue";
// import DoctorsPage from "@/views/DoctorsPage.vue";
import GalleryPage from "@/views/GalleryPage.vue";
import MakeAppointment from "@/views/MakeAppointment.vue";
import SinglePage from "@/views/SinglePage.vue";
import WebsiteContents from "@/views/WebsiteContents.vue";

import { createRouter, createWebHistory } from "vue-router";
import AlbumDetails from "@/views/AlbumDetails.vue";



const routes = [
  {
    path: "/", 
    component: HomePage, 
    props: true 
  },
  { 
    path: "/contact-us", 
    component: ContactUs, 
    props: true 
  },
  { 
    path: "/departments", 
    component: DepartmentDoctor, 
    props: true 
  },
  {
    path: "/department/:alias",
    name: "DepartmentDetails",
    component: DepartmentDetails,
    props: true,
  },
  { 
    path: "/about", 
    component: AboutUs, 
  },
  { 
    path: "/appointment", 
    component: MakeAppointment, 
    props: true 
  },
  { 
    path: "/doctors", 
    component: DoctorsList, 
    props: true 
  },
  { 
    path: "/gallery", 
    component: GalleryPage, 
    props: true 
  },
  { path: '/album/:id', name: 'AlbumDetails', component: AlbumDetails }
  ,
  {
    path: "/doctors/:id",
    name: "DoctorDetails",
    component: DoctorDetails,
    props: true,
  },
  {
    path: "/:contentType",
    name: "ContentList",
    component: WebsiteContents,
    props: (route) => ({
      contentType: route.params.contentType,
    }),
  },
  {
    path: "/:contentType/:alias",
    name: "SinglePage",
    component: SinglePage,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Check for debug parameter on initial load
if (window.location.search.includes('debug=1')) {
  localStorage.setItem('debug', '1');
}

export default router;
